import React from "react";
import { SubHeader, BlueLine } from "../../pages/shared.styled.components";
import { VideoCardDeck } from "./styled.components";
import VideoCard from "../VideoCard";
import Card from "react-bootstrap/Card";

interface Video {
  src: string;
  title: string;
}

interface VideoLibraryProps {
  videos: Video[];
}

const VideoLibrary: React.FC<VideoLibraryProps> = ({ videos }) => {
  return (
    <>
      <SubHeader>Video Library</SubHeader>
      <BlueLine />

      <VideoCardDeck>
        {videos.map(video => (
          <VideoCard
            src={video.src}
            titleComponent={
              <Card.Title className="mt-2">{video.title}</Card.Title>
            }
          />
        ))}
      </VideoCardDeck>
    </>
  );
};

export default VideoLibrary;
