import React from "react";
import { StyledCard } from "./styled.components";

interface VideoCardProps {
  src: string;
  titleComponent: React.ReactElement;
}

const VideoCard: React.FC<VideoCardProps> = ({ src, titleComponent }) => {
  return (
    <StyledCard key={`video-card-${src}`.replace(" ", "")}>
      <iframe
        className="embed-responsive-item"
        src={src}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="video"
      />

      {titleComponent}
    </StyledCard>
  );
};

export default VideoCard;
