import styled from "styled-components";
import CardDeck from "react-bootstrap/CardDeck";

export const VideoCardDeck = styled(CardDeck)`
  justify-content: center;

  ${({ theme }) => theme.lg`
    justify-content: normal;
  `}

  .card {
    ${({ theme }) => theme.sm`
      min-width: 290px;
      max-width: 290px;
    `};

    ${({ theme }) => theme.xl`
      min-width: 320px;
      max-width: 320px;
    `};
  }
`;
