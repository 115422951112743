import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { siteDarkBlue, siteGray, sitePurple } from "../../providers/Theme";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

interface ImageBannerProps {
  src: string;
  height?: string;
}

export const ImageBanner = styled(Row)<ImageBannerProps>`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;

  ${props => props.height && `height: ${props.height};`};

  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.05);

  padding: 2rem 1rem;

  ${({ theme }) => theme.sm`
    padding: 4rem 2rem;
   `}
`;

export const FlyerLink = styled.a`
  color: white;
  font-weight: bold;

  &:hover {
    color: white;
  }
`;

export const VideoContainer = styled.div.attrs({
  className: "embed-responsive embed-responsive-16by9 mx-auto"
})`
  top: 0;
  left: 0;
  clip-path: inset(2px 2px);
  ${({ theme }) => theme.sm`
      width: 75%;
   `}
`;

interface StyledContainerProps {
  bgColor?: string;
  color?: string;
}

export const StyledContainer = styled(Container)<StyledContainerProps>`
  background-color: ${props => props.bgColor ?? siteGray};
  color: ${props => props.color ?? "black"};
`;

export const VideoCardDeck = styled(CardDeck)`
  display: flex; /* Use flexbox to manage layout */
  flex-wrap: wrap; /* Allow cards to wrap into multiple rows */
  gap: 20px; /* Add some gap between the cards (optional) */

  .card {
    display: flex;
    flex-direction: column;

    max-width: 440px;
    margin: auto;

    iframe {
      width: 100%;
      height: 300px;
      max-width: 100%;
    }

    ${({ theme }) => theme.xs`
      min-width: 100%;
    `}

    ${({ theme }) => theme.sm`
      min-width: 440px;
    `}
  }
`;

const buttonStates = `
    background-color: white !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: none;
    color: ${siteDarkBlue};
    text-decoration: underline !important;
`;

export const StyledButton = styled(Button)`
  border-radius: 1000px;
  padding: 0.625rem 1.875rem;
  font-size: 1.15rem;
  font-weight: bold;
  align-self: center;

  color: ${siteDarkBlue};
  background-color: white;
  border-color: white !important;

  h3 {
    margin-bottom: 0px;
  }

  ${({ theme }) => theme.xs`
    min-width: 100%;
    margin-top: 25px;
  `}

  ${({ theme }) => theme.md`
    min-width: 80%;
  `}

  ${({ theme }) => theme.lg`
    margin-top: 0;
    width: 100%;
  `}

  ${({ theme }) => theme.xl`
    margin-top: 25px;
    width: 100%;
  `}

  &:hover {
    ${buttonStates}
  }

  &:active {
    ${buttonStates}
  }

  &:focus {
    ${buttonStates}
  }
`;

export const EnrollNowText = (
  <div style={{ color: sitePurple }}>ENROLL NOW</div>
);

export const StyledImage = styled(Image)`
  width: 100%; /* Make the image take up full width of the column */
  height: 200px; /* Set a consistent height */
  object-fit: contain; /* Ensures the full image is displayed without cutting off */
  object-position: center; /* Center the image in the container */
`;

export const TextUnderLineContainer = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;
