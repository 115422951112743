import styled from "styled-components";
import Card from "react-bootstrap/Card";

export const StyledCard = styled(Card)`
  min-height: 200px;
  height: 100%;
  border: none;
  padding-top: 1rem;
  text-align: center;
  background-color: inherit;
`;
