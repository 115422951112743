import React from "react";
import HomePageBanner from "../../components/HomePageBanner";
import TakeActionCard from "../../components/TakeActionCard";
import TextLinkInternal from "../../components/TextLinkInternal";
import EpiFirstEpiFastPoster from "../../assets/epi-first-epi-fast-poster.pdf";
import HolographicBg from "../../assets/KRF-holographic-bg.jpg";
import Container from "react-bootstrap/Container";
import DonateIcon from "../../assets/donate-icon.png";
import GetTrained from "../../assets/get-trained.png";
import JoinOurTeam from "../../assets/join-our-team.png";
import LearnAboutFa from "../../assets/learn-about-FA.png";
import OneInThreePeople from "../../assets/1-in-3-people.png";
import RevenueLost from "../../assets/revenue-lost.png";
import BoostProfits from "../../assets/boost-profits.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardDeck from "react-bootstrap/CardDeck";
import {
  siteGray,
  siteDarkBlue,
  sitePurple,
  siteDarkGreen,
  siteLightBlue
} from "../../providers/Theme";
import {
  ImageBanner,
  FlyerLink,
  VideoContainer,
  StyledContainer,
  VideoCardDeck,
  StyledButton,
  EnrollNowText,
  StyledImage,
  TextUnderLineContainer
} from "./styled.components";
import {
  BodyText,
  StyledJumbotron,
  ListContainer,
  ListItem,
  Header,
  BODY_FONT_SIZE
} from "../shared.styled.components";
import { useCustomRouter } from "../../hooks/useCustomRouter";
import VideoCard from "../../components/VideoCard";
import { goToExternalUrl } from "../../utils";
import {
  AZ_EPICOURSE_SCHOOLS_LINK,
  EPICOURSE_FOOD_SERVICE_LINK,
  NATIONAL_EPICOURSE_SCHOOLS_LINK,
  SUBSCRIBE_TO_NEWSLETTER_LINK
} from "../../constants";

const Home: React.FC = () => {
  const customRouter = useCustomRouter();

  const goToPath = (path: string) => {
    customRouter.append(path);
  };

  return (
    <>
      <HomePageBanner />

      <ImageBanner src={HolographicBg}>
        <Container fluid="md">
          <Row className="justify-content-center my-1">
            <Col>
              <VideoContainer>
                <iframe
                  className="embed-responsive-item"
                  src="https://player.vimeo.com/video/747017119?h=d6e9e16e8b"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                />
              </VideoContainer>
            </Col>
          </Row>
        </Container>
      </ImageBanner>

      <StyledJumbotron backgroundColor={siteDarkBlue}>
        <Container>
          <h2 className="pb-4 pl-3">Food Allergy Statistics</h2>

          <ListContainer>
            <ListItem>33 million Americans live with food allergies.</ListItem>
            <ListItem>
              Every <b>ten seconds</b>, a severe allergic reaction to food sends
              someone to the emergency room.
            </ListItem>
            <ListItem>
              There is no cure for allergies. You can manage allergies with
              prevention and treatment. It is among the country's most common
              yet overlooked diseases.
            </ListItem>
            <ListItem>
              Life-saving epinephrine, such as Epinephrine Auto-Injectors (ex.
              EpiPen®) must be administered within minutes of anaphylaxis and
              epinephrine is the <b>ONLY</b> available treatment.
            </ListItem>
            <ListItem>
              1 in 13 children have food allergies - about two in each U.S.
              classroom.
            </ListItem>
            <ListItem>
              Over 15% of children with food allergies have had an allergic
              reaction in school.
            </ListItem>
            <ListItem>
              Between 2007 and 2016, there was a <b>377% increase</b> in
              diagnosis of anaphylactic food reactions.
            </ListItem>
          </ListContainer>
        </Container>
      </StyledJumbotron>

      <StyledJumbotron backgroundColor="white">
        <TextUnderLineContainer>
          <StyledContainer
            className="text-center p-5"
            bgColor={siteLightBlue}
            color="white"
            onClick={() => goToPath("trainings")}
            style={{ cursor: "pointer" }}
          >
            <h2>TAKE KYAH'S EPICOURSES FOR FREE</h2>
            <BodyText>
              Take our free, online training courses to learn more about
              anaphylaxis, its causes, and the proper emergency response using
              our learning resources. Enroll today to prevent food allergy
              fatalities.
            </BodyText>
          </StyledContainer>
        </TextUnderLineContainer>
      </StyledJumbotron>

      <StyledJumbotron className="mb-0" backgroundColo={siteDarkBlue}>
        <Container className="mb-2">
          <h2 className="pb-4 text-center">Kyah's EPICourse for Schools</h2>

          <div style={{ fontSize: BODY_FONT_SIZE }}>
            Promote safety in schools and protect children. Watch the enrollment
            tutorial videos below to learn how to quickly enroll in{" "}
            <b>National Kyah's EPICourse for Schools</b> and{" "}
            <b>Kyah's EPICourse for Arizona Schools</b>.
          </div>

          <VideoCardDeck>
            <VideoCard
              src="https://player.vimeo.com/video/1020302165?title=0"
              titleComponent={
                <StyledButton
                  onClick={() => {
                    goToExternalUrl(NATIONAL_EPICOURSE_SCHOOLS_LINK);
                  }}
                >
                  <div>National Kyah's EPICourse for Schools</div>
                  {EnrollNowText}
                </StyledButton>
              }
            />

            <VideoCard
              src="https://player.vimeo.com/video/969100868?title=0"
              titleComponent={
                <StyledButton
                  onClick={() => {
                    goToExternalUrl(AZ_EPICOURSE_SCHOOLS_LINK);
                  }}
                >
                  <div>Kyah's EPICourse for Schools</div>
                  {EnrollNowText}
                </StyledButton>
              }
            />
          </VideoCardDeck>
        </Container>
      </StyledJumbotron>

      <StyledJumbotron backgroundColo={siteDarkBlue}>
        <Container className="mb-2">
          <h2 className="pb-4 text-center">
            Kyah's EPICourse for Food Service
          </h2>

          <div style={{ fontSize: BODY_FONT_SIZE }}>
            Save lives, reduce death and grow your business. Watch the
            enrollment tutorial video below to learn how to quickly enroll in{" "}
            <b>Kyah's EPICourse for Food Service</b>. Also available in{" "}
            <b>Spanish</b>!
          </div>

          <Row>
            <Col sm={12} md={4}>
              <StyledImage fluid src={OneInThreePeople} />
            </Col>
            <Col sm={12} md={4}>
              <StyledImage fluid src={RevenueLost} />
            </Col>
            <Col sm={12} md={4}>
              <StyledImage fluid src={BoostProfits} />
            </Col>
          </Row>

          <VideoCardDeck>
            <VideoCard
              src="https://player.vimeo.com/video/1018070550?title=0"
              titleComponent={
                <StyledButton
                  onClick={() => {
                    goToExternalUrl(EPICOURSE_FOOD_SERVICE_LINK);
                  }}
                >
                  <div>Kyah's EPICourse for Food Service</div>
                  {EnrollNowText}
                </StyledButton>
              }
            />
          </VideoCardDeck>
        </Container>
      </StyledJumbotron>

      <StyledJumbotron marginBottom="0" backgroundColor="white">
        <StyledContainer
          bgColor={sitePurple}
          color="white"
          className="text-center p-5"
        >
          <h2>NOBODY ELSE NEEDS TO DIE</h2>

          <BodyText>
            Food allergy awareness saves lives. The Kyah Rayne Foundation is
            dedicated to training students, teachers, and families to recognize
            and respond to food allergies and anaphylaxis. We're working to
            increase access to life-saving epinephrine with the goal of
            preventing food allergy fatalities.
          </BodyText>

          <BodyText>
            Check out our{}
            <FlyerLink
              className="mx-1"
              href={EpiFirstEpiFastPoster}
              target="_blank"
            >
              flyer
            </FlyerLink>
            and SHARE to help save lives.
          </BodyText>
        </StyledContainer>
        <StyledContainer
          bgColor={siteDarkGreen}
          color="white"
          className="text-center p-5"
        >
          <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>

          <BodyText>
            Our newsletters are free and offer periodic updates on the Kyah
            Rayne Foundation's efforts to prevent anaphylaxis-related deaths and
            other developments regarding food allergy awareness.
          </BodyText>

          <StyledButton
            onClick={() => {
              goToExternalUrl(SUBSCRIBE_TO_NEWSLETTER_LINK);
            }}
          >
            <div>SUBSCRIBE</div>
          </StyledButton>
        </StyledContainer>
      </StyledJumbotron>

      <Container>
        <div className="text-center">
          <Header color="black">TAKE ACTION</Header>
        </div>

        <Row>
          <Container>
            <CardDeck className="d-flex flex-wrap justify-content-center">
              <TakeActionCard
                icon={LearnAboutFa}
                actionElement={
                  <TextLinkInternal
                    text="Learn more about food allergies >>"
                    path="food-allergy-basics"
                  />
                }
              />

              <TakeActionCard
                icon={GetTrained}
                actionElement={
                  <TextLinkInternal
                    text="Take our food allergy training >>"
                    path="trainings"
                  />
                }
              />

              <TakeActionCard
                icon={JoinOurTeam}
                actionElement={
                  <TextLinkInternal
                    text="Join our team >>"
                    path="get-involved/volunteers-and-trainers"
                  />
                }
              />

              <TakeActionCard
                icon={DonateIcon}
                actionElement={
                  <TextLinkInternal
                    text="Donate to our organization >>"
                    path="donate"
                  />
                }
              />
            </CardDeck>
          </Container>
        </Row>
      </Container>

      <StyledJumbotron
        className="mb-0"
        backgroundColor={siteGray}
        color="black"
      >
        <Container className="text-center">
          <h4 className="text-center pb-3">Disclaimer</h4>
          <p>
            The information provided here is for educational purposes only and
            is not intended to replace the medical advice of a doctor or
            healthcare provider. This information is provided by the Kyah Rayne
            Foundation, a qualified 501(c)(3) tax-exempt organization. By
            accessing this website and the resources located on this website,
            the visitor acknowledges that there is no physician-patient
            relationship between them and the authors. If you think you are
            observing a medical emergency such as but not limited to an allergic
            reaction, call 911 immediately.
          </p>
        </Container>
      </StyledJumbotron>
    </>
  );
};

export default Home;
